::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #45a735;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #189405;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #000;
}

.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 30px;
  height: 30px;
  top: 100%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #45a735 solid;
  border-bottom: 2px #45a735 solid;
}

.arrow:before {
  content: "";
  width: 15px;
  height: 15px;
  top: 80%;
  margin: -18px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #45a735 solid;
  border-bottom: 1px #45a735 solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

/*  --------   pagination -------- */
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 16px;
  gap: 5px;
}

.pagination .page-num {
  padding: 2px 7px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #45a735;
  color: #fff;
}

.pagination .active {
  background-color: #45a735;
  color: #fff;
}

@media (min-width: 0px) and (max-width: 480px) {
  .policy {
    padding: 0 20px;
  }
}

@media (min-width: 481px) and (max-width: 992px) {
  .policy {
    padding: 0 70px;
  }
}

@media (min-width: 993px) {
  .policy {
    padding: 0 150px;
  }
}

/* ---------------  carousel (react-slick)-------------------- */

.slick-current {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: auto;
  max-width: fit-content;
}
